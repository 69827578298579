<template>
  <div
    class="home"
    ref="home"
    :style="{ background: companyInfo.backgroundColor }"
  >
    <div class="d-flex align-items-center justify-content-between mx-4">
      <div class="company-block">
        <img :src="companyInfo.logo" />
        <span class="company-name">{{ companyInfo.name }}</span>
      </div>
      <div class="total-status">
        實到:{{ employeeCount }}人/總數:{{ employeeCount }}人
      </div>
      <div class="page-status">
        目前為第 {{ page.index > totalPage ? totalPage : page.index }}頁，總共{{
          totalPage
        }}頁
      </div>
      <div class="time-status">
        {{ timestamp }}
      </div>
    </div>
    <div class="employee-record row mx-0">
      <div
        v-for="employee in employeeStatusData"
        :key="employee.user_id"
        class="col-2 employee-info my-2"
      >
        <div class="employee-image">
          <img
            v-if="employee.face_image.indexOf('jpeg') == -1"
            :src="
              employee.face_image.substr(0, 22) + employee.face_image.substr(22)
            "
          />
          <img
            v-else
            :src="
              employee.face_image.substr(0, 23) + employee.face_image.substr(23)
            "
          />
        </div>
        <div class="employee-txt">
          <span>ID：{{ employee.id }}</span>
          <span>姓名：{{ employee.name }}</span>

          <span
            >群組：<span v-for="(group, index) in employee.group" :key="index"
              >{{ group }}
            </span>
            <span v-show="employee.group.length == 0">無</span></span
          >

          <div class="d-flex" v-if="employee.FaceCounts.length === 0">
            <span class="notwork-status work-status bg-danger">未上班</span>
          </div>
          <div
            class="d-flex"
            v-else-if="employee.FaceCounts[0].tag === 'FRONT_DOOR'"
          >
            <span class="inwork-status work-status bg-success">已上班</span>
          </div>
          <div class="d-flex" v-else>
            <span class="outwork-status work-status bg-warning">已下班</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPeopleCount } from "@/api/index.js";
import { getCompanyLogo } from "@/api/index.js";
import { getRecord } from "@/api/index.js";

export default {
  name: "HomeView",
  data() {
    return {
      employeeStatusData: [],
      employeeCount: 0,
      peopleStatus: {},
      page: {
        limit: 12,
        index: 1,
        device_group: '',
      },
      totalPage: 0,
      companyInfo: {},
      timestamp: "",
      employeeNextStatusData: [],
    };
  },
  async created() {
    setInterval(() => {
      this.getNow();
    }, 1000);
    getCompanyLogo({})
      .then((res) => {
        this.companyInfo = res.data;
        this.$refs.home.style.background = this.companyInfo.backgroundColor;
      })
      .catch((error) => {
        console.log(error);
      });
    await getPeopleCount({})
      .then((res) => {
        this.peopleStatus = res.data.peopleStatus;
      })
      .catch((error) => {
        console.log(error);
      });
    await getRecord({
      limit: 12,
      index: 1,
      device_group: this.$route.query.device_group,
    })
      .then((res) => {
        this.employeeCount = res.data.count;
        this.employeeStatusData = res.data.rows;
        this.totalPage = Math.ceil(res.data.rows.length / this.page.limit);
        console.log('total page', this.totalPage)
      })
      .catch((error) => {
        console.log(error);
      });
    await getRecord({
      limit: this.page.limit,
      index: this.page.index + 1,
      device_group: this.$route.query.device_group,
    })
      .then((res) => {
        this.employeeNextStatusData = res.data.rows;
      })
      .catch((error) => {
        console.log(error);
      });
    setInterval(() => {
      this.page.index++;
      if (this.page.index <= this.totalPage) {
        this.getPeopleCount();
        this.getRecord();
        this.getNextRecord();
      } else {
        this.page.index = 1;
        this.getPeopleCount();
        this.getRecord();
        this.getNextRecord();
      }
    }, process.env.VUE_APP_TURN_SECOND || 5000);
  },
  mounted() {},
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "/" +
        (today.getMonth() + 1 < 10 ? "0" : "") +
        (today.getMonth() + 1) +
        "/" +
        today.getDate();
      const time =
        (today.getHours() < 10 ? "0" : "") +
        today.getHours() +
        ":" +
        (today.getMinutes() < 10 ? "0" : "") +
        today.getMinutes() +
        ":" +
        (today.getSeconds() < 10 ? "0" : "") +
        today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    getRecord() {
      this.employeeStatusData = this.employeeNextStatusData;
    },
    getNextRecord() {
      getRecord({
        limit: this.page.limit,
        index:
          this.page.index + 1 === this.totalPage + 1 ? 1 : this.page.index + 1,
        device_group: this.$route.query.device_group,
      })
        .then((res) => {
          if (res.data.count !== this.employeeCount) {
            this.employeeCount = res.data.count;
            this.totalPage = Math.ceil(this.employeeCount / this.page.limit);
          }
          this.employeeNextStatusData = res.data.rows;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPeopleCount() {
      getPeopleCount({})
        .then((res) => {
          this.peopleStatus = res.data.peopleStatus;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.employee-txt {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.home {
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}
.company-block {
  display: flex;
  align-items: center;
  img {
    height: 7vw;
  }
  .company-name {
    font-size: 2vw;
    font-weight: bold;
  }
}
.total-status,
.page-status,
.time-status {
  font-size: 1.8vw;
}
.employee-record {
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: calc(100% - 7vw);
}
.employee-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(50% - 1.75vw);
  font-size: 1vw;

  .employee-image {
    overflow: hidden;
    width: 80%;
    aspect-ratio: 241 / 329;
    display: flex;
    justify-content: center;
    margin: auto;
    img {
      width: 100%;
      max-width: 16vw;
      object-fit: cover;
    }
  }
  .work-status {
    padding: 1% 2%;
    border-radius: 5px;
    color: #fff;
    font-size: 1.3vw;
    margin: auto;
  }
}
</style>
